<template>
	<div>
		<header-banner id="headerBox" :bgimg="info.posterUrl" />
		<switch-lang style="margin-top: 10px" from="list" class="sl" />

		<div class="info-container" v-if="info.title">
			<div class="title" v-if="$i18n.locale === 'zh'">{{info.title}}</div>
			<div class="title" v-else>{{info.english_title}}</div>
			<div class="title_info_box">
				<div class="title-info">
					<img loading="lazy" src="@/assets/img/album/icon_time@2x.png" alt=""> {{info.beginDate}} - {{getEndDate}}
				</div>
				<div class="title-info" v-if="$i18n.locale === 'zh'">
					<img loading="lazy" src="@/assets/img/album/icon_local@2x.png" alt="">{{info.address}}
				</div>
				<div class="title-info" v-else>
					<img loading="lazy" src="@/assets/img/album/icon_local@2x.png" alt="">{{info.english_address}}
				</div>
			</div>

		</div>
		<div class="asset">
			<div class="asset-title" :class="{ 'header-fixed': headerFixed }">
				<div class="asset-item" :class="{ 'active': activeTab === 'pic' }" @click="activeTab = 'pic'">
					{{$t('photos')}}
				</div>
				<div v-if="isVideoShow">
					<div class="asset-item" :class="{ 'active': activeTab === 'video' }" @click="activeTab = 'video'">
						{{$t('videos')}}
					</div>
				</div>
			</div>

			<div :class="{ 'full': showImgList.length > 0 }" class="asset-container1" v-show="activeTab === 'pic'"
				id="piccon">
				<div class="pic-type-box">
					<!-- <ul>
						<li v-for="(item,index) in pic_type_list" :key="index">
							{{item.languageJson[$i18n.locale].name}}
						</li>
					</ul> -->
					<div class="list">
						<div class="item">
							<div class="item-1" :class="{'item-active':pic_type_id==0}" @click="changePicType(0)">
								{{$i18n.locale == 'zh'?'全部':'All'}}
							</div>
						</div>
						<div class="item" v-for="(item,index) in pic_type_list" :key="index">
							<div class="item-1" :class="{'item-active':pic_type_id==item.id}"
								@click="changePicType(item.id)">
								{{item.languageJson[$i18n.locale].name}}
							</div>
						</div>
					</div>
				</div>
				<div v-for="(item,index) in showImgList" :key="index" class="img-box">
					<img :src="item.url" :data-url="item.printUrl" :data-index="index"
						:data-preview_url="item.preview_url" loading="lazy" @error="imgError()" @click="previewImg2()">
				</div>
				<div v-if="isLoadingShow">
					<Spinner size="big" :message="$i18n.locale == 'zh'?'加载中...':'Loading...'" />
				</div>
				<div style="padding: 1vh 0;text-align: center;" v-show="showTips">{{$t('noMore')}}</div>
			</div>
			<div v-if="activeTab === 'video'">
				<div class="asset-container1">
					<div class="video-item" style="margin-bottom: 20px;text-align: center;" v-show="item.replayAddress"
						v-for="(item, index) in videoList" :key="index" @click="preview(item, 2)">
						<div class="box">
							<div class="video-img-box">
								<img class="post-img" :src="item.posterUrl?item.posterUrl:item.fullUrl" style="width: 100%;" alt="" srcset="">
								<div class="doing">{{item.beginDate}} {{item.beginTime}} - {{item.endTime}}</div>
							</div>
							<div class="title" :title="$i18n.locale === 'zh'?change(item.name):change(item.en_name)">
								<span>{{$i18n.locale === 'zh'?change(item.name):change(item.en_name)}}</span>
							</div>
						</div>
					</div>
				</div>

			</div>

		</div>
	</div>
</template>
<script>
	import vueWaterfallEasy from 'vue-waterfall-easy'
	import request from '@/request.js'
	import axios from 'axios'
	import Spinner from 'vue-simple-spinner'
	export default {
		name: 'DolphinDetail',
		components: {
			// vueWaterfallEasy
			Spinner
		},
		created() {
			let active = this.$route.query.active
			this.activeTab = active ? active : "pic"
			let id = this.$route.query.id
			const _that = this;
			axios({
				method: 'post',
				url: this.BASE_URL + '/gateway/api-tenant/api/meeting/list',
				data: {
					"type": 1,
					"size": 1000,
					"meetingType": "",
					"name": "",
					"current": 1,
					"tenantId": "1001",
				}
			}).then((response) => {
				_that.info = response.data.datas.filter((item) => item.id == id)
				_that.info = _that.info[0]
				_that.initInfo();
				// _that.initImageList()
				_that.initVideoList()
				_that.getPicType()
			});

		},
		mounted() {
			this.scroll()
		},
		data() {
			return {
				headerFixed: false,
				videoList: [],
				showImgList: [],
				showTips: false,
				picIndex: 1,
				imgList: [],
				info: {},
				titles: '',
				activeTab: 'pic',
				halfPageWidth: parseInt(window.document.documentElement.clientWidth / 2),
				flag: 0,
				isMask: true,
				imageRequest: {
					current: 1, //第几页
					size: 30, //一页多少个
					count: 0, //记录数
					isLastPage: false, //是否最后一页
				},
				videoRequest: {
					current: 1, //第几页
					size: 9999, //一页多少个
					count: 0, //记录数
				},
				pic_type_list: [],
				pic_type_id: 0,
				isVideoShow: true,
				isLoadingShow: false,
			}
		},
		methods: {
			initInfo() {
				// this.info.id="1541605212546433026"
				this.info.title = this.info.name;
				this.info.languageJson = JSON.parse(this.info.languageJson);
				this.info.english_address = this.info.languageJson.en.address;
				this.info.english_title = this.info.languageJson.en.name;

				//临时隐藏视频分类
				// if(this.info.id=='1664463435838103553'){
				// 	this.isVideoShow=false
				// }
			},
			initImageList() {
				let _that = this;
				this.isLoadingShow = true
				// this.showImgList=[]
				this.showTips = false
				axios({
					method: 'post',
					url: this.BASE_URL + '/gateway/api-live/api/livePrint/list',
					data: {
						"meetingId": this.info.id,
						"current": this.imageRequest.current,
						"size": this.imageRequest.size,
						"auditStatus": 1,
						"printTypeIds": this.pic_type_id == 0 ? [] : [this.pic_type_id],
					}
				}).then((response) => {
					_that.isLoadingShow = false
					if (response.status == 200 && response.data.resp_code == 200) {
						if (_that.info.id == "1569864404065624066") {
							response.data.datas.forEach(item => {
								item.url = `${item.printUrl}?x-oss-process=image/resize,p_100`
								item.preview_url = `${item.printUrl}?x-oss-process=image/resize,p_100`
								_that.showImgList.push(item)
							})
						} else {
							response.data.datas.forEach(item => {
								item.url = `${item.printUrl}?x-oss-process=image/resize,p_50`
								item.preview_url = `${item.printUrl}?x-oss-process=image/resize,p_50`
								_that.showImgList.push(item)
							})
						}

						// _that.showImgList.push(response.data.datas[0])
						// console.log(_that.showImgList)
						sessionStorage.setItem('boao_imgs', JSON.stringify(_that.showImgList))
						//_that.$refs.waterfall.waterfallOver()
						_that.showTips = _that.showImgList.length > 0 ? false : true;
						this.imageRequest.current++;
						if (_that.showImgList.length == response.count || response.data.datas.length < _that
							.imageRequest.size) {
							_that.imageRequest.isLastPage = true
						}
					}

				});
			},
			initVideoList() {
				let _that = this;
				axios({
					method: 'post',
					url: this.BASE_URL + '/gateway/api-live/api/liveSetting/list/all',
					data: {
						"name": "",
						"pid": this.info.id,
						"meetingStatus": "",
						"publishStatus": "",
						"current": this.videoRequest.current,
						"size": this.videoRequest.size,
						"beginCreateDate": "",
						"endCreateDate": ""
					}
				}).then((response) => {
					if (response.status == 200 && response.data.resp_code == 200) {
						response.data.datas.forEach(item => {
							if (item.languageJson) {
								var languageJson = JSON.parse(item.languageJson);
								item.languageJson = languageJson
								item.en_name = item.languageJson.en.name
							} else {
								item.en_name = ""
							}

						})
						_that.videoList = response.data.datas;
					}

				});
			},
			imgError() {
				let img = event.srcElement;
				var url = img.dataset.url
				img.src = img.dataset.url + "?v=1"
				var index = img.dataset.index
				img.dataset.preview_url = url
				this.showImgList[index].preview_url = url + "?v=1"
				sessionStorage.setItem('boao_imgs', JSON.stringify(this.showImgList))
			},
			previewImg2() {
				let img = event.srcElement;
				this.$router.push({
					path: '/dolphinPreview',
					query: {
						path: encodeURIComponent(img.dataset.preview_url),
						title: '',
						type: 1,
						index: img.dataset.index
					}
				})
			},
			preview(item, type) {
				console.log(item);
				if (this.$i18n.locale === 'zh') {
					this.titles = item.name
				} else {
					this.titles = item.en_name
				}
				this.$router.push({
					path: '/dolphinPreview',
					query: {
						path: encodeURIComponent(item.replayAddress),
						title: encodeURIComponent(this.titles),
						type
					}
				})
			},
			getPicType() {
				let _that = this
				axios({
					method: 'get',
					// url: this.BASE_URL+'/gateway/api-live/api/livePrintType/list?meetingId='+this.info.id,
					url: this.BASE_URL + '/gateway/api-live/api/livePrintType/list?meetingId=' + this.info.id,
				}).then((response) => {
					// console.log(response)
					let datas = response.data.datas

					// console.log(datas)
					if (datas.length > 0) {
						datas.forEach(function(item) {
							item.languageJson = JSON.parse(item.languageJson)
						})
						_that.pic_type_list = datas
					}
					_that.pic_type_id = 0
					_that.initImageList()

				});
			},
			changePicType(id) {
				this.pic_type_id = id
				this.showImgList = []
				this.imageRequest.current = 1
				this.imageRequest.isLastPage = false
				this.initImageList()
			},
			//滚动条距离底部
			getScrollBottom() {
				// 变量 scrollHeight 是滚动条的总高度
				let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight

				// 变量 windowHeight 是可视区的高度
				let windowHeight = document.documentElement.clientHeight || document.body.clientHeight

				// 变量scrollTop为当前页面的滚动条纵坐标位置
				let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
				// 滚动条到底部得距离 = 滚动条的总高度 - 可视区的高度 - 当前页面的滚动条纵坐标位置
				var scrollBottom = scrollHeight - windowHeight - scrollTop
				return scrollBottom;
			},
			scroll() {
				// let isLoading = false
				window.onscroll = () => {
					if (this.getScrollBottom() == 0 && this.isLoadingShow == false && !this.imageRequest.isLastPage) {
						// isLoading = true
						// axios.get(`https://randomuser.me/api/`).then(response => {
						// 	person.push(response.data.results[0])
						// 	isLoading = false
						// })
						this.initImageList();
					}
				}
			},
			change(value) {
				let data = encodeURI(value);
				data = data.replace(/%C2%A0/g, '%20');
				data = decodeURI(data);
				return data;
			},
			
		},
		computed:{
			getEndDate(){
				let end_date=this.info.endDate
				let temp=end_date.split("-")
				return temp[2]
			}
		}
		// beforeRouteLeave(to,from,next){
		// 	console.log(to,from)
		// 	if(to.name=='DolphinPreview'){
		// 		from.meta.keepAlive=true
		// 	}else{
		// 		from.meta.keepAlive=false
		// 	}

		// 	if(!from.meta.keepAlive){
		// 		this.$destroy(true);
		// 	}
		// 	next();
		// }
	}
</script>
<style lang="less" scoped>
	.header-fixed {
		position: fixed;
		top: 0;
		width: 100%;
		left: 0;
		padding: 0 10px;
		z-index: 1;
	}

	.list-play {
		position: absolute;
		margin-top: -25%;
		transform: translate(-50%, -50%);
		width: 20%;
	}

	.info-container {
		position: relative;
		border-top-left-radius: 30px;
		border-top-right-radius: 30px;
		// margin-top: -100px;
		background: #fff;
		border-bottom: 8px solid #F8F7FA;
		padding-bottom: 10px;
	}

	.title {
		font-weight: bold;
		font-size: 16px;
		padding: 20px 20px 8px 20px;

		&-info {
			display: flex;
			align-items: center;
			color: #8C8A97;
			font-size: 14px;
			padding: 5px 20px;

			img {
				width: 14px;
				margin-right: 4px;
			}
		}
	}

	.asset {
		padding: 0 10px;

		&-item {
			padding: 10px;
			font-size: 14px;

			&.active {
				position: relative;
				font-weight: bold;
				font-size: 14px;

				&::after {
					position: absolute;
					content: '';
					width: 20px;
					height: 4px;
					bottom: 0px;
					left: 50%;
					border-radius: 4px;
					transform: translate(-50%, 0);
					background: #322897;
				}
			}
		}

		&-title {
			font-size: 15px;
			background: #fff;
			display: flex;
			margin-bottom: 10px;
		}

		&-container {
			display: flex;
			justify-content: space-between;
			height: 30vh;
			overflow: hidden;

			.column {
				flex: 0 0 49%;
			}

			.image-box {
				max-height: 250px;
				object-fit: cover;
				margin-bottom: 10px;
				overflow: hidden;

				img {
					display: block;
					width: 100%;
				}
			}
		}
	}

	.empty {
		padding-top: 100px;
		color: #ddd;
		font-size: 14px;
		text-align: center;
	}

	.loading {
		&:extend(.empty);
		padding: 10px 0;
	}

	/deep/ .img-inner-box {
		overflow: hidden;
		box-shadow: none !important;
		border-radius: 0 !important;
	}

	.video {
		width: 98%;
		height: 30%;
		background: #000;
		object-fit: cover;
		display: block;
	}

	.full {
		height: 100vh;
	}
</style>

<style type="text/css">
	.loading {
		background: rgba(0, 0, 0, 0.3) !important;
		height: 100% !important;
		width: 100% !important;
		text-align: center !important;
		left: 0 !important;
		padding-top: 10px;
		position: fixed !important;
	}

	.loading .dot {
		margin-top: 86% !important;
	}

	.mask {
		width: 100%;
		height: 100%;
		position: absolute;
		background: black;
		opacity: 0.3;
	}

	.vue-waterfall-easy-container .loading {
		bottom: 0 !important;
		top: 10px !important;
	}

	.vue-waterfall-easy-container>.loading.first {
		-webkit-transform: initial !important;
		transform: initial !important;
	}

	.sl {
		display: none;
	}

	.img-box {
		width: calc(50% - 10px);

		display: inline-block;
		padding: 0 5px;
	}

	.img-box img {
		height: 112px;
	}

	.pic-type-box {
		margin-bottom: 20px;
	}

	.pic-type-box .list {
		/* width: 100% !important; */
		/* display: flex; */
		/* justify-content: center;
		white-space: nowrap;
		overflow-y: auto; */
		/* overflow-x: scroll; */
		
		overflow-y: auto;
		    justify-content: center;
		    white-space: nowrap;
	}

	.pic-type-box .list .item {
		/* flex: 1; */
		text-align: center;
		padding-top: 10px;
		padding-bottom: 10px;
		cursor: pointer;
		margin-left: 10px;
		margin-right: 10px;
		display: inline-block;
		white-space: nowrap;
	}

	.pic-type-box .list .item .item-1 {
		padding: 8px 24px;
		border: 1px solid #EEEEEE;
		border-radius: 20px;
		display: inline-block;
		color: #666666;
	}

	.pic-type-box .list .item .item-1:hover {
		color: white;
		background: #322897;
		border: 1px solid #322897;
	}

	.pic-type-box .list .item .item-active {
		color: white;
		background: #322897;
		border: 1px solid #322897;
		font-weight: bold;
	}

	/* .pic-type-box .list .item:hover{
		background: #ccc;
		color: #322897;
		font-weight: bold;
	} */
	@media (min-width: 768px) {
		#headerBox {
			display: none;
		}

		.info-container {
			margin-top: initial !important;
			text-align: center;
		}

		.title_info_box {
			display: flex;
			justify-content: center;
		}

		.asset-title {
			justify-content: center;
		}

		.vue-waterfall-easy-scroll {
			overflow-x: initial !important;
			overflow-y: initial !important;
		}

		.full {
			height: initial !important;
		}

		.asset-container {
			height: initial !important;
			overflow: initial !important;
		}

		.video-item {
			width: 33.33%;
			position: relative;
			display: inline-block;
		}

		.list-play {
			width: 5% !important;
			margin-top: initial !important;
			top: 44%;
		}

		.video {
			width: 50% !important;
			display: inline-block !important;
		}

		.sl {
			display: inline-block;
			color: #000000 !important;
			cursor: pointer;
		}

		.title {
			font-size: 32px !important;
		}

		.title-info {
			font-size: 18px !important;
		}

		.title-info img {
			width: 18px !important;
		}

		.asset-item {
			font-size: 18px !important;
		}

		.asset-title .active {
			font-size: 18px !important;
		}

		.loading .dot {
			margin-top: 12% !important;
		}

		.asset-container1 {
			width: 1200px;
			margin: 0 auto;
			box-sizing: border-box;
		}

		.img-box {
			width: calc(25% - 20px);
			padding: 0 10px;
			display: inline-block;
		}

		.img-box img {
			width: 100%;
			height: 160px !important;
		}

		.list-play {
			right: 44%;
			transform: translateY(-50%);
		}

		.pic-type-box .list {
			/* width: 100% !important; */
			/* display: flex;
			justify-content: center;
			white-space: nowrap;
			overflow-y: inherit; */
			/* overflow-x: scroll; */
		}

		.pic-type-box .list .item {
			flex: 1;
			text-align: center;
			padding-top: 10px;
			padding-bottom: 10px;
			cursor: pointer;
			margin-left: 10px;
			margin-right: 10px;
			display: inline-block;
			white-space: nowrap;
		}

		.box {
			padding: 0 10px;
			cursor: pointer;
		}

		.video-img-box {
			height: 210px;
			position: relative;
		}

		.video-img-box img {
			width: 100%;
			height: 100%;
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
		}

		.video-img-box .doing {
			position: absolute;
			bottom: 0;
			color: white;
			padding: 10px 14px;
		}

		.box .title {
			font-size: 20px !important;
			padding: 14px;
			min-height: 84px;
			text-align: left;
			font-weight: 400;
			display: flex;
			box-sizing: border-box;
			border: 1px solid #eee;
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;
			box-shadow: 0px 2px 4px #ccc;
		}

		.box .title span {
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			height: 100%;
		}
	}
</style>
<style>
	html,
	body,
	#app {
		width: 100%;
		height: 100%;
		background: white;
		/* background-size: cover; */
	}

	@media (min-width: 768px) {

		html,
		body,
		#app {
			width: 100%;
			height: 100%;
			background: white;
			background-size: cover;
		}
	}
</style>
