<template>
  <div class="container">
    <div class="close" @click="close"></div>
    <!-- <img loading=”lazy”  class="img" v-if="type === '1'" :src="path" alt=""> -->
    <div id="swiper"  class="swiper-container" style="width: 100%;" v-if="type === '1'">
      <div class="swiper-wrapper" :options="swiperOption">
        <div  class="swiper-slide" v-for='(item, index) of imgList' :key='item.id'>
          <img  :ref="`pic${index}`" class='swiper-img swiper-lazy' :src='item.preview_url' v-show="reload" @load="imgCompleted(item.preview_url)" />
          <div class="swiper-lazy-preloader"></div>
        </div>
      </div>
        <div class="swiper-button-prev" style="width: 20px; left: 20px;outline: none;" @click="prev">
          <img src="./icon_arr_left.png" style="width: 30px" alt="">  
        </div><!--左箭头。如果放置在swiper-container外面，需要自定义样式。-->
        <div class="swiper-button-next" style="width: 20px; right: 10px;outline: none;" @click="next">
          <img src="./icon_arr_right.png" style="width: 30px" alt="">  
        </div>
    </div>
    <div v-else class="video prism-player" id="J_prismPlayer"></div>
    <div class="info">
      <div v-if="title && title !== 'undefined'">{{ title }}</div>
      <div class="ctrl">
		<div class="origin-pic" :class="{ 'hidden': !showOriginPic }" v-show="completed" >{{$t('completed')}}{{fileSize}}</div>
		<div class="origin-pic" :class="{ 'hidden': !showOriginPic }" v-show="!completed" @click="showOriginPicFunc">{{$t('fullImage')}}{{fileSize}}</div>
        <!-- <div class="origin-pic" :class="{ 'hidden': !showOriginPic }" v-show="type === '1'" @click="showOriginPicFunc">{{$t('fullImage')}}{{fileSize}}</div> -->
        <!-- <a target="_blank"  v-if="type === '1'" @click="showTip" class="download">
          <img loading=”lazy” style="visibility: hidden;width:10px;" :src="path" alt="">
          <div v-show="show" class="tip">{{$t('pressToDownload')}}</div>
        </a> -->
      </div>
    </div>
	
	<!-- <div class="original" @click="showOriginPicFunc">
		<span>查看原图</span>
	</div> -->
  </div>
</template>
<script>
// import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import swiper from 'swiper'
// import style (<= Swiper 5.x)
import 'swiper/css/swiper.css'

export default {
  name: 'DolphinPreview',
  data () {
    return {
      swiperOption: {
        lazyLoading: true,
        params: {}
      },
      path: null,
      title: null,
      show: false,
      showOriginPic: true,
      type: null,
      imgList: JSON.parse(sessionStorage.getItem('boao_imgs')),
      fileSize: '',
      index: 0,
	reload:true,
	completed:false,
    }
  },
  created() {
	console.log(this.imgList);
    console.log(this.$t('viewImage'));
    const { path, title } = this.$route.query
    this.title = decodeURIComponent(title) || ''
    this.path = decodeURIComponent(path)
    document.title = this.title
    this.type = this.$route.query.type
    this.index = Number(this.$route.query.index)
    // if(this.path.includes('?')) {
    //   this.getFileSize(this.path.split('?')[0])
    // }
	this.showOriginPic=this.type==2?false:true
  },
  mounted() {
    const self = this
    if (this.type === '2') {
      new window.Aliplayer({
        id: 'J_prismPlayer',
        source: this.path,
        width: '100%',
        height: document.documentElement.clientWidth > 640 ? '480px' : '75vw',
        setCover: `${this.path}?x-oss-process=video/snapshot,t_10000,m_fast`,
        autoplay: true,
        isLive: false ,
        rePlay: false,
        playsinline: true,
        preload: true,
        controlBarVisibility: "hover",
        useH5Prism: true
      }, function () {
          console.log('The player is created');
        }
      );
    } else {
      const swiperItem = new swiper('#swiper', {
        lazy: {
          loadPrevNext: true,
        },
        observer: true, 
        observeParents: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        on:{
          slideChange: function(){
            // console.log('改变了，activeIndex为'+ this.activeIndex);
            self.path = self.imgList[this.activeIndex].preview_url
            self.title = self.imgList[this.activeIndex].title
            // self.imgList[this.activeIndex].url = self.imgList[this.activeIndex].src
            self.index = this.activeIndex
            // self.getFileSize(self.path)
			if(self.path.includes('?')){
				self.completed=false;
			}else{
				self.completed=true;
			}
			
          }
        }
      })
      swiperItem.slideTo(this.index)
    }
  },
  methods: {
    getFileSize() {
      return
    // var xhr = new XMLHttpRequest();
    // xhr.open('HEAD', url, true);
    // xhr.onreadystatechange = () => {
    //   if ( xhr.readyState == 4 ) {
    //     if ( xhr.status == 200 ) {
    //       let fileSize = xhr.getResponseHeader('Content-Length')
    //       this.fileSize = `(${(fileSize/1024/1024).toFixed(1)}M)`
    //     }
    //   }
    // };
    // xhr.send(null);

    },
    showOriginPicFunc() {
      console.log(this.path);
      if (this.path.includes('?')) {
		this.reload=false
		var _this=this;
		setTimeout(function(){
			_this.reload=true;
			console.log(_this.path.split('?')[0])
			_this.imgList[_this.index].preview_url = _this.path.split('?')[0]
			// this.$refs[`pic${this.index}`].src = this.path.split('?')[0]
			_this.$refs[`pic${_this.index}`].url = _this.path.split('?')[0]
			console.log('this.$refs[`pic${this.index}`]: ', _this.$refs[`pic${_this.index}`]);
		},0)
        
      }
      console.log();
        // this.showOriginPic = false
    },
    close () {
      this.$router.back(-1)
    },
    showTip() {
      this.show = true
      setTimeout(() => {
        this.show = false
      }, 1000);
    },
	prev(){
		console.log(this.path);
	},
	next(){
		console.log(this.path);
	},
	imgCompleted(url){
		if(!url.includes("?")){
			console.log("asdasdasdsa")
		console.log(url)
		this.completed=true;
		}
		
	}
  },
  
}
</script>
<style lang="less" scoped>
  .swiper-container{
    --swiper-theme-color: transparent;/* 设置Swiper风格 */
    --swiper-navigation-color: transparent;/* 单独设置按钮颜色 */
    --swiper-navigation-size: 20px;/* 设置按钮大小 */
  }
  .container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    background: #000;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    // position: absolute;
    // top: 0;
    // left: 0;
    // transform: translate(-50%, -50%);
    max-height: 100vh;
    width: 100%;
  }
  .close {
    position: fixed;
    top: 20px;
    right: 20px;
    width: 20px;
    height: 20px;
    background: url('~@/assets/img/album/icon_close@2x.png') no-repeat;
    background-size: contain;
	z-index: 99;
  }
  .download {
    position: relative;
    width: 30px;
    height: 30px;
    background-image: url('~@/assets/img/album/icon_download@2x.png');
    background-repeat: no-repeat;
    background-color: #4D4D4D;
    border-radius: 15px;
    background-size: 50% 50%;
    background-position: center;
    .img, .video {
      opacity: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 999;
    }
  }
  .tip {
    position: absolute;
    padding: 4px 8px;
    color: #fff;
    top: -140%;
    right: 0px;
    white-space: nowrap;
    background:lightcoral;
    border-radius: 4px;
  }
  .origin-pic {
    background:#4D4D4D;
    height: 30px;
    line-height: 30px;
    text-align: center;
    padding: 0 16px;
    border-radius: 20px; 
    color: #fff;
    font-size: 12px;
    white-space: nowrap;
	cursor: pointer;
  }
  .info {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    color:#fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    font-size: 14px;
	z-index: 99;
  }
  .ctrl {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  .hidden {
    visibility: hidden;
  }
  .swiper-wrapper {
    display: flex;
    align-items: center;
  }
  // /deep/ .pause {
  //   display: none !important;
  // }
  
  .original{
	display: inline-block;
	padding: 4px 8px 4px 8px;
	color: white;
	border: 1px solid white;
	position: absolute;
	bottom: 2%;
	font-size: 14px;
  }
</style>
<style type="text/css">
	#swiper{
		width: 100%;
	}
	@media (min-width: 768px){
		#swiper{
			width: 50% !important;
		}
	}
</style>