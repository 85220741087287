<template>
	<div class="background" style="">
		<!-- <header-banner/> -->
		<div style="text-align: center;">
			<!-- <img loading=”lazy” src="@/assets/img/live/background.png" style="width: 100%;height: 100%;background-size: cover;" alt=""> -->
			<switch-lang style="margin-top: 10px" from="login" />
			<div>
				<img loading=”lazy” src="@/assets/img/live/istif.png" id="logo" alt="">
			</div>
			<div>
				<img loading=”lazy” src="@/assets/img/live/live.png" id="title" alt="">
			</div>

		</div>
		<div class="info-container">
			<div v-for="(item, index) in activeList" :key="index" @click="goDetail(item)" class="box">
				<div class="cover">
					<img loading=”lazy” :src="item.posterUrl" alt="">
				</div>
				<!-- <div class="title" v-if="$i18n.locale === 'zh'">{{item.title}}</div> -->
				<!-- <div class="title" v-else>{{item.english_title}}</div> -->
			</div>
		</div>
	</div>
</template>
<script>
	// import BannerHeader from ''
	import Vue from 'vue'
	import axios from 'axios'
	import request from '@/request.js'
	export default {
		name: 'DolphinList',
		data() {
			return {
				list: []
			}
		},
		computed:{
			activeList() {
				return this.list.filter((item) => item.status == 1)
			}
		},
		created() {
			axios({
				method: 'post',
				url: this.BASE_URL+'/gateway/api-tenant/api/meeting/list',
				data: {
					"type": 1,
					"size": 1000,
					"meetingType": "",
					"name": "",
					"current": 1,
					"tenantId":"1001"
				}
			}).then((response)=>{
				this.list=response.data.datas
			});
		},
		methods: {
			goDetail(item) {
				// sessionStorage.setItem("boao_info",JSON.stringify(item))
				this.$router.push({
					// name: 'DolphinDetail',
					path: '/dolphinDetail', 
					query:{
						id:item.id,
						t: +new Date()
					}
				})
			}
		},
	}
</script>
<style lang="less" scoped>
	.info-container {
		position: relative;
		border-top-left-radius: 30px;
		border-top-right-radius: 30px;
		//margin-top: -100px;
		//background: #fff;
		//border-bottom: 8px solid #F8F7FA;
		padding-bottom: 100px;
	}

	.box {
		padding: 4vw;
	}

	.cover {
		img {
			width: 100%;
			height: 150px;
			border-radius: 2vw;
		}
	}

	.title {
		font-weight: bold;
		font-size: 16px;
		position: absolute;
		font-family: PingFangSC;
	}

	.background {
		// width: 100%;
		// height: 100%;
		background: url(../../assets/img/live/background.png) no-repeat top left;
		background-size: cover;
	}

	#logo {
		width: 65%;
		margin-top: 20px;
	}

	#title {
		width: 60%;
		margin-top: 60px;
		margin-bottom: 30px;
	}

	@media (min-width: 768px){
		html,body,#app{
			background: url(../../assets/img/live/background.png) no-repeat top left;
		}
		#logo {
			width: 30%;
		}
		
		#title {
			width: 18%;
		}
		.background {
			min-height: 100%;
		}
		.info-container{
			display: flex;
			align-items: center;
			justify-content: flex-start;
			flex-wrap: wrap;
		}
		.info-container .box{
			width: 33.33%;
			box-sizing: border-box;
		}
		.cover img{
			height: 210px;
			cursor: pointer;
		}
	}
	
</style>

<style>
	html,body,#app{
		width: 100%;
		height: 100%;
		/* background: url(../../assets/img/live/background.png) no-repeat top left; */
		/* background-size: cover; */
	}
	
	@media (min-width: 768px){
		html,body,#app{
			width: 100%;
			height: 100%;
			/* background: url(../../assets/img/live/background.png) no-repeat top left; */
			background-size: cover;
		}
	}
</style>
